import React, { useState, useMemo } from "react";
import * as PR from "../../../prime-modules/index";
import "./Shoppartner.scss";
import { useFormik } from "formik";
import { uploadAssetsApi } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { trimFormData } from "../../../utils";
import { getAllAssets } from "../../../genericFunctions/getAssets";
import ImagePreview from "../generic/imagePreview";
import { uploadWidthHeightImage } from "../../../utils/reuse";
// import { isValid } from 'i18n-iso-countries';

const Shoppartner = ({ assets, disableSave }) => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);
  const [caseStudy1, setCaseStudy1] = useState(null);
  const [caseStudy2, setCaseStudy2] = useState(null);
  const [caseStudy3, setCaseStudy3] = useState(null);
  const [caseStudy4, setCaseStudy4] = useState(null);
  const [caseStudy5, setCaseStudy5] = useState(null);
  const [successStory1, setSuccessStory1] = useState(null);
  const [successStory2, setSuccessStory2] = useState(null);
  const [successStory3, setSuccessStory3] = useState(null);
  const [successStory4, setSuccessStory4] = useState(null);
  const [successStory5, setSuccessStory5] = useState(null);
  const [successStory6, setSuccessStory6] = useState(null);
  const [successStory7, setSuccessStory7] = useState(null);
  const [successStory8, setSuccessStory8] = useState(null);
  const [successStory9, setSuccessStory9] = useState(null);
  const [successStory10, setSuccessStory10] = useState(null);
  const [successStory11, setSuccessStory11] = useState(null);
  const [successStory12, setSuccessStory12] = useState(null);
  const [successStory13, setSuccessStory13] = useState(null);
  const [successStory14, setSuccessStory14] = useState(null);
  const [successStory15, setSuccessStory15] = useState(null);
  const [successStory16, setSuccessStory16] = useState(null);
  const [successStory17, setSuccessStory17] = useState(null);
  const [successStory18, setSuccessStory18] = useState(null);
  const [successStory19, setSuccessStory19] = useState(null);
  const [successStory20, setSuccessStory20] = useState(null);
  const [partnerLeftImage, setPartnerLeftImage] = useState(null);

  // const validationSchema = Yup.object().shape({
  //   BRAND0001: validateTextField(5, 100, ""),
  //   BRAND0002:validateTextField(5, 100, ""),
  //   BRAND0003:validateTextField(5, 100, ""),
  //   BRAND0004: validateTextField(5, 100, ""),
  //   BRAND0005: validateTextField(5, 100, ""),
  //   BRAND0006: validateTextField(5, 100, ""),
  //   BRAND0007: validateTextField(5, 100, ""),
  //   BRAND0008: validateTextField(5, 100, ""),
  //   BRAND0009: validateTextField(5, 100, ""),
  //   BRAND0010: validateTextField(5, 100, ""),
  //   BRAND0011: validateTextField(5, 100, ""),
  //   BRAND0012: validateTextField(5, 100, ""),
  //   BRAND0013: validateTextField(5, 100, ""),
  //   BRAND0014: validateTextField(5, 100, ""),
  //   BRAND0015: validateTextField(5, 100, ""),
  //   BRAND0016: validateTextField(5, 100, ""),
  //   BRAND0017: validateTextField(5, 100, ""),
  //   BRAND0018: validateTextField(5, 100, ""),
  //   BRAND0019: validateTextField(5, 100, ""),
  //   BRAND0020: validateTextField(5, 100, ""),
  //   BRAND0021: validateTextField(5, 100, ""),
  //   BRAND0022: validateTextField(5, 100, ""),
  //   BRAND0023: validateTextField(5, 100, ""),
  //   BRAND0024: validateTextField(5, 100, ""),
  //   BRAND0025: validateTextField(5, 100, ""),
  //   BRAND0026: validateTextField(5, 100, ""),
  //   BRAND0027: validateTextField(5, 100, ""),
  //   BRAND0028: validateTextField(5, 100, ""),
  //   BRAND0029: validateTextField(5, 100, ""),
  //   BRAND0030: validateTextField(5, 100, ""),
  //   BRAND0031: validateTextField(5, 100, ""),
  //   BRAND0032: validateTextField(5, 100, ""),
  //   BRAND0033: validateTextField(5, 100, ""),
  //   BRAND0034: validateTextField(5, 100, ""),
  //   BRAND0035: validateTextField(5, 100, ""),
  //   BRAND0036: validateTextField(5, 100, ""),
  //   BRAND0037: validateTextField(5, 100, ""),
  //   BRAND0038: validateTextField(5, 100, ""),
  //   BRAND0039: validateTextField(5, 100, ""),
  //   BRAND0040: validateTextField(5, 100, ""),
  //   BRAND0041: validateTextField(5, 100, ""),
  //   BRAND0042: validateTextField(5, 100, ""),
  //   BRAND0043: validateTextField(5, 100, ""),
  //   BRAND0044: validateTextField(5, 100, ""),
  //   BRAND0045: validateTextField(5, 100, ""),
  //   BRAND0046: validateTextField(5, 100, ""),
  //   BRAND0047: validateTextField(5, 100, ""),
  //   BRAND0048: validateTextField(5, 100, ""),
  //   BRAND0049: validateTextField(5, 100, ""),
  //   BRAND0050: validateTextField(5, 100, ""),
  //   BRAND0091: validateTextField(5, 100, ""),
  //   BRAND0092: validateTextField(5, 100, ""),
  //   BRAND0093: validateTextField(5, 100, ""),
  //   BRAND0094: validateTextField(5, 100, ""),
  //   BRAND0095: validateTextField(5, 100, ""),
  //   BRAND0096: validateTextField(5, 100, ""),
  //   BRAND0097: validateTextField(5, 100, ""),
  //   BRAND0098: validateTextField(5, 100, ""),

  // });




  const handleSubmit = async (data) => {
    const formData = new FormData();
    const trimmedFormData = trimFormData(data);
    formData.append("screen", "branding");
    for (const field in trimmedFormData) {
      if (trimmedFormData.hasOwnProperty(field)) {
        const value = trimmedFormData[field];
        if (
          value &&
          typeof value === "string" &&
          !(
            value.startsWith("iVBORw0KGgoAAAANSUhEUg") ||
            value.startsWith("/9j/4AAQSkZJRgA")
          )
        ) {
          formData.append(field, value);
        }
      }
    }
    const getCMSResponse = (response) => {
      if (response.result === "SUCCESS") {
        if (response.data === "success") {
          if (toast.current)
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: " Updated successfully",
            });
        }
        getAllAssets(toast, dispatch, headers);
      } else if (response.result === "FAILED" && response.error) {
        const error = response.error;
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
    };
    uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
  };

  const formik = useFormik({
    initialValues: assets,
    // validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="branding">
      <div className="card">
        {/* <h2>Home Screen Settings</h2> */}
        <form onSubmit={formik.handleSubmit}>
          <div className="grid-nogutter mt-4">
            <label htmlFor="unlockSection">
              <h3 className="mb-3 mt-1">Menus List</h3>
            </label>
            <div className="grid align-items-center mt-4">
                {/* Title 1 */}
                <div className="col-3">
                  <div className="grid align-items-center">
                    <div className="col-3">
                      <label htmlFor="BRAND0091" className="cmsLabels">
                        Title 1
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="card file-upload-section">
                        <PR.InputText
                          id="BRAND0091"
                          name="BRAND0091"
                          aria-describedby="BRAND0091"
                          value={formik.values.BRAND0091}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0091 && formik.errors.BRAND0091 ? (
                        <div className="error-msg">{formik.errors.BRAND0091}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* Title 3 */}
                <div className="col-3">
                  <div className="grid align-items-center">
                    <div className="col-3">
                      <label htmlFor="BRAND0093" className="cmsLabels">
                        Title 3
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="card file-upload-section">
                        <PR.InputText
                          id="BRAND0093"
                          name="BRAND0093"
                          aria-describedby="BRAND0093"
                          value={formik.values.BRAND0093}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0093 && formik.errors.BRAND0093 ? (
                        <div className="error-msg">{formik.errors.BRAND0093}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                 {/*  Title 5 */}
                 <div className="col-3">
                  <div className="grid align-items-center">
                    <div className="col-3">
                      <label htmlFor="BRAND0095" className="cmsLabels">
                        Title 5
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="card file-upload-section">
                        <PR.InputTextarea
                          autoResize
                          id="BRAND0095"
                          name="BRAND0095"
                          aria-describedby="BRAND0095"
                          value={formik.values.BRAND0095}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0095 && formik.errors.BRAND0095 ? (
                        <div className="error-msg">{formik.errors.BRAND0095}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/*  Title 7 */}
                <div className="col-3">
                  <div className="grid align-items-center">
                    <div className="col-3">
                      <label htmlFor="BRAND0097" className="cmsLabels">
                        Title 7
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="card file-upload-section">
                        <PR.InputTextarea
                          autoResize
                          id="BRAND0097"
                          name="BRAND0097"
                          aria-describedby="BRAND0097"
                          value={formik.values.BRAND0097}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0097 && formik.errors.BRAND0097 ? (
                        <div className="error-msg">{formik.errors.BRAND0097}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
            </div>
            <div className="grid align-items-center">
               {/* Title 2 */}
               <div className="col-3">
                  <div className="grid align-items-center">
                    <div className="col-3">
                      <label htmlFor="BRAND0050" className="cmsLabels">
                        Title 2
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="card file-upload-section">
                        <PR.InputText
                          id="BRAND0050"
                          name="BRAND0050"
                          aria-describedby="BRAND0050"
                          value={formik.values.BRAND0050}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0050 && formik.errors.BRAND0050 ? (
                        <div className="error-msg">{formik.errors.BRAND0050}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                  {/* Title 4 */}
                  <div className="col-3">
                  <div className="grid align-items-center">
                    <div className="col-3">
                      <label htmlFor="BRAND0094" className="cmsLabels">
                        Title 4
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="card file-upload-section">
                        <PR.InputText
                          id="BRAND0094"
                          name="BRAND0094"
                          aria-describedby="BRAND0094"
                          value={formik.values.BRAND0094}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0094 && formik.errors.BRAND0094 ? (
                        <div className="error-msg">{formik.errors.BRAND0094}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/*  Title 6 */}
                <div className="col-3">
                  <div className="grid align-items-center">
                    <div className="col-3">
                      <label htmlFor="BRAND0096" className="cmsLabels">
                        Title 6
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="card file-upload-section">
                        <PR.InputTextarea
                          autoResize
                          id="BRAND0096"
                          name="BRAND0096"
                          aria-describedby="BRAND0096"
                          value={formik.values.BRAND0096}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0096 && formik.errors.BRAND0096 ? (
                        <div className="error-msg">{formik.errors.BRAND0096}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/*  Title 8 */} 
                <div className="col-3">
                  <div className="grid align-items-center">
                    <div className="col-3">
                      <label htmlFor="BRAND0098" className="cmsLabels">
                        Title 8
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="card file-upload-section">
                        <PR.InputTextarea
                          autoResize
                          id="BRAND0098"
                          name="BRAND0098"
                          aria-describedby="BRAND0098"
                          value={formik.values.BRAND0098}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0098 && formik.errors.BRAND0098 ? (
                        <div className="error-msg">{formik.errors.BRAND0098}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <PR.Divider />
          {/* Header title */}
          <div className="grid-nogutter">
            <label htmlFor="unlockSection">
              <h3 className="mb-3 mt-1">Header Section</h3>
            </label>
            {/* Top Header */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0010" className="cmsLabels">
                  Top Title
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0010"
                    name="BRAND0010"
                    aria-describedby="BRAND0010"
                    value={formik.values.BRAND0010}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0010 && formik.errors.BRAND0010 ? (
                  <div className="error-msg">{formik.errors.BRAND0010}</div>
                ) : null}
              </div>
            </div>
            {/* Header Animation 1 */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0012" className="cmsLabels">
                  Animation 1
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0012"
                    name="BRAND0012"
                    aria-describedby="BRAND0012"
                    value={formik.values.BRAND0012}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0012 && formik.errors.BRAND0012 ? (
                  <div className="error-msg">{formik.errors.BRAND0012}</div>
                ) : null}
              </div>
            </div>
            {/* Header Animatioln 2 */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0013" className="cmsLabels">
                  Animation 2
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0013"
                    name="BRAND0013"
                    aria-describedby="BRAND0013"
                    value={formik.values.BRAND0013}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0013 && formik.errors.BRAND0013 ? (
                  <div className="error-msg">{formik.errors.BRAND0013}</div>
                ) : null}
              </div>
            </div>
            {/* Header Animatioln 3 */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0014" className="cmsLabels">
                  Animation 3
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0014"
                    name="BRAND0014"
                    aria-describedby="BRAND0014"
                    value={formik.values.BRAND0014}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0014 && formik.errors.BRAND0014 ? (
                  <div className="error-msg">{formik.errors.BRAND0014}</div>
                ) : null}
              </div>
            </div>

            {/*  Bottom Title */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0011" className="cmsLabels">
                  Bottom Title
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputTextarea
                    autoResize
                    id="BRAND0011"
                    name="BRAND0011"
                    aria-describedby="BRAND0011"
                    value={formik.values.BRAND0011}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0011 && formik.errors.BRAND0011 ? (
                  <div className="error-msg">{formik.errors.BRAND0011}</div>
                ) : null}
              </div>
            </div>
          </div>
          <PR.Divider />
          {/* Video Section */}
          <div className="grid-nogutter">
            <label htmlFor="unlockSection">
              <h3 className="mb-3 mt-1">Video Section</h3>
            </label>
            <div className="card">
              <div className="grid">
                {/* Description 1 */}
                <div className="col-12 md:col-3">
                  <div className="grid grid-nogutter align-items-center mb-2">
                    <div className="col-12 mb-2">
                      <label htmlFor="BRAND0015" className="cmsLabels">
                        Section 1
                      </label>
                    </div>
                    <div className="col-12">
                      <div className="file-upload-section">
                        <PR.InputText
                          id="BRAND0015"
                          name="BRAND0015"
                          aria-describedby="BRAND0015"
                          value={formik.values.BRAND0015}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0015 && formik.errors.BRAND0015 ? (
                        <div className="error-msg">{formik.errors.BRAND0015}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Description 2*/}
                <div className="col-12 md:col-3">
                  <div className="grid grid-nogutter align-items-center mb-2">
                    <div className="col-12 mb-2">
                      <label htmlFor="BRAND0016" className="cmsLabels">
                        Section 2
                      </label>
                    </div>
                    <div className="col-12">
                      <div className="file-upload-section">
                        <PR.InputTextarea
                          autoResize
                          id="BRAND0016"
                          name="BRAND0016"
                          aria-describedby="BRAND0016"
                          value={formik.values.BRAND0016}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0016 && formik.errors.BRAND0016 ? (
                        <div className="error-msg">{formik.errors.BRAND0016}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Description 3 */}
                <div className="col-12 md:col-3">
                  <div className="grid grid-nogutter align-items-center mb-2">
                    <div className="col-12 mb-2">
                      <label htmlFor="BRAND0017" className="cmsLabels">
                        Section 3
                      </label>
                    </div>
                    <div className="col-12">
                      <div className="file-upload-section">
                        <PR.InputText
                          id="BRAND0017"
                          name="BRAND0017"
                          aria-describedby="BRAND0017"
                          value={formik.values.BRAND0017}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0017 && formik.errors.BRAND0017 ? (
                        <div className="error-msg">{formik.errors.BRAND0017}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Description 4 */}
                <div className="col-12 md:col-3">
                  <div className="grid grid-nogutter align-items-center mb-2">
                    <div className="col-12 mb-2">
                      <label htmlFor="BRAND0018" className="cmsLabels">
                        Section 4
                      </label>
                    </div>
                    <div className="col-12">
                      <div className="file-upload-section">
                        <PR.InputText
                          id="BRAND0018"
                          name="BRAND0018"
                          aria-describedby="BRAND0018"
                          value={formik.values.BRAND0018}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0018 && formik.errors.BRAND0018 ? (
                        <div className="error-msg">{formik.errors.BRAND0018}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Button*/}
                <div className="col-12 md:col-6">
                  <div className="grid grid-nogutter align-items-center mb-2">
                    <div className="col-3">
                      <label htmlFor="BRAND0019" className="cmsLabels">
                        Button
                      </label>
                    </div>
                    <div className="col-8">
                      <div className="file-upload-section">
                        <PR.InputText
                          id="BRAND0019"
                          name="BRAND0019"
                          aria-describedby="BRAND0019"
                          value={formik.values.BRAND0019}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.BRAND0019 && formik.errors.BRAND0019 ? (
                        <div className="error-msg">{formik.errors.BRAND0019}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />

          {/* eSIM Information Section */}

          <div className="grid-nogutter">
            <label htmlFor="unlockSection">
              <h3 className="mb-3 mt-1"> eSIM Information Section</h3>
            </label>
            {/* Top Header */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0020" className="cmsLabels">
                  Description
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0020"
                    name="BRAND0020"
                    aria-describedby="BRAND0020"
                    value={formik.values.BRAND0020}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0020 && formik.errors.BRAND0020 ? (
                  <div className="error-msg">{formik.errors.BRAND0020}</div>
                ) : null}
              </div>
            </div>
            {/* Top Header */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0021" className="cmsLabels">
                  Split Text
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0021"
                    name="BRAND0021"
                    aria-describedby="BRAND0021"
                    value={formik.values.BRAND0021}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0021 && formik.errors.BRAND0021 ? (
                  <div className="error-msg">{formik.errors.BRAND0021}</div>
                ) : null}
              </div>
            </div>
          </div>

          <PR.Divider />
          {/* White label info Section */}
          <div className="grid-nogutter">
            <label htmlFor="unlockSection">
              <h3 className="mb-3 mt-1"> White label info Section</h3>
            </label>
            {/*  Description 1 */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0023" className="cmsLabels">
                  Description 1
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0023"
                    name="BRAND0023"
                    aria-describedby="BRAND0023"
                    value={formik.values.BRAND0023}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0023 && formik.errors.BRAND0023 ? (
                  <div className="error-msg">{formik.errors.BRAND0023}</div>
                ) : null}
              </div>
            </div>

            {/*  Description 1 */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0024" className="cmsLabels">
                  Description 2
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0024"
                    name="BRAND0024"
                    aria-describedby="BRAND0024"
                    value={formik.values.BRAND0024}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0024 && formik.errors.BRAND0024 ? (
                  <div className="error-msg">{formik.errors.BRAND0024}</div>
                ) : null}
              </div>
            </div>

            {/*  Description 1 */}
            <div className="grid grid-nogutter align-items-center mb-4">
              <div className="col-2">
                <label htmlFor="BRAND0025" className="cmsLabels">
                  Description 3
                </label>
              </div>
              <div className="col-4">
                <div className="card file-upload-section">
                  <PR.InputText
                    id="BRAND0025"
                    name="BRAND0025"
                    aria-describedby="BRAND0025"
                    value={formik.values.BRAND0025}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.BRAND0025 && formik.errors.BRAND0025 ? (
                  <div className="error-msg">{formik.errors.BRAND0025}</div>
                ) : null}
              </div>
            </div>
          </div>

          <PR.Divider />

          {/* Why work with us Section */}
          <div className="grid-nogutter">
            <div className="grid col-12">
              <div className="col-6">
                <label htmlFor="unlockSection">
                  <h3 className="mb-3 mt-1">Why work with us Section</h3>
                </label>
                {/*  Title 1 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0033" className="cmsLabels">
                      Title 1
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0033"
                        name="BRAND0033"
                        aria-describedby="BRAND0033"
                        value={formik.values.BRAND0033}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0033 && formik.errors.BRAND0033 ? (
                      <div className="error-msg">{formik.errors.BRAND0033}</div>
                    ) : null}
                  </div>
                </div>

                {/*  Title 2 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0034" className="cmsLabels">
                      Title 2
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0034"
                        name="BRAND0034"
                        aria-describedby="BRAND0034"
                        value={formik.values.BRAND0034}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0034 && formik.errors.BRAND0034 ? (
                      <div className="error-msg">{formik.errors.BRAND0034}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-6">
                {/*  Animation 1 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0026" className="cmsLabels">
                      Animation 1
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0026"
                        name="BRAND0026"
                        aria-describedby="BRAND0026"
                        value={formik.values.BRAND0026}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0026 && formik.errors.BRAND0026 ? (
                      <div className="error-msg">{formik.errors.BRAND0026}</div>
                    ) : null}
                  </div>
                </div>
                {/*  Animation 2 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0027" className="cmsLabels">
                      Animation 2
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0027"
                        name="BRAND0027"
                        aria-describedby="BRAND0027"
                        value={formik.values.BRAND0027}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0027 && formik.errors.BRAND0027 ? (
                      <div className="error-msg">{formik.errors.BRAND0027}</div>
                    ) : null}
                  </div>
                </div>
                {/*  Animation 3 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0028" className="cmsLabels">
                      Animation 3
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0028"
                        name="BRAND0028"
                        aria-describedby="BRAND0028"
                        value={formik.values.BRAND0028}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0028 && formik.errors.BRAND0028 ? (
                      <div className="error-msg">{formik.errors.BRAND0028}</div>
                    ) : null}
                  </div>
                </div>
                {/*  Animation 4 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0029" className="cmsLabels">
                      Animation 4
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0029"
                        name="BRAND0029"
                        aria-describedby="BRAND0029"
                        value={formik.values.BRAND0029}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0029 && formik.errors.BRAND0029 ? (
                      <div className="error-msg">{formik.errors.BRAND0029}</div>
                    ) : null}
                  </div>
                </div>
                {/*  Animation 5 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0030" className="cmsLabels">
                      Animation 5
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0030"
                        name="BRAND0030"
                        aria-describedby="BRAND0030"
                        value={formik.values.BRAND0030}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0030 && formik.errors.BRAND0030 ? (
                      <div className="error-msg">{formik.errors.BRAND0030}</div>
                    ) : null}
                  </div>
                </div>
                {/*  Animation 6 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0031" className="cmsLabels">
                      Animation 6
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0031"
                        name="BRAND0031"
                        aria-describedby="BRAND0031"
                        value={formik.values.BRAND0031}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0031 && formik.errors.BRAND0031 ? (
                      <div className="error-msg">{formik.errors.BRAND0031}</div>
                    ) : null}
                  </div>
                </div>{" "}
                {/*  Animation 7 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="BRAND0032" className="cmsLabels">
                      Animation 7
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0032"
                        name="BRAND0032"
                        aria-describedby="BRAND0032"
                        value={formik.values.BRAND0032}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0032 && formik.errors.BRAND0032 ? (
                      <div className="error-msg">{formik.errors.BRAND0032}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />
          {/* Case study Section */}
          <div className="grid grid-nogutter">
            <div className="col-12">
              <label htmlFor="panelsSection">
                <h3 className="mb-3 mt-1">Case study Section</h3>
              </label>

              {/* Top Header */}
              <div className="grid grid-nogutter align-items-center mb-4">
                <div className="col-1">
                  <label htmlFor="BRAND0035" className="cmsLabels">
                    Header Title
                  </label>
                </div>
                <div className="col-6">
                  <div className="card file-upload-section">
                    <PR.InputText
                      id="BRAND0035"
                      name="BRAND0035"
                      aria-describedby="BRAND0035"
                      value={formik.values.BRAND0035}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.BRAND0035 && formik.errors.BRAND0035 ? (
                    <div className="error-msg">{formik.errors.BRAND0035}</div>
                  ) : null}
                </div>
              </div>
              <div className="card">
                <div className="grid">
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-1</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-2</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-3</h4>
                    </div>
                  </div>
                </div>
                <div className="label-input-wrapper align-items-center">
                  {/* Panel-1 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-1 Header 1*/}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="BRAND0036"
                              name="BRAND0036"
                              aria-describedby="BRAND0036"
                              value={formik.values.BRAND0036}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.BRAND0036 &&
                            formik.errors.BRAND0036 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0036}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-1 Header 2*/}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="BRAND0037"
                              name="BRAND0037"
                              aria-describedby="BRAND0037"
                              value={formik.values.BRAND0037}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.BRAND0037 &&
                            formik.errors.BRAND0037 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0037}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-1 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12 success-story-image-upload">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="BRAND0099"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setCaseStudy1(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "BRAND0099",
                                  toast,
                                  dispatch,
                                  headers,
                                  "branding"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          <ImagePreview
                            formikError={formik.errors.BRAND0099}
                            defaultImage={assets.BRAND0099}
                            newImage={caseStudy1}
                          />
                          {caseStudy1 &&
                            formik.touched.BRAND0099 &&
                            formik.errors.BRAND0099 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0099}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-2 Section */}
                  <div className="col">
                    <div className="panel-card">
                      {/* Panel-2 Header 1*/}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="BRAND0038"
                              name="BRAND0038"
                              aria-describedby="BRAND0038"
                              value={formik.values.BRAND0038}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.BRAND0038 &&
                            formik.errors.BRAND0038 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0038}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-2 Header 2*/}
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="BRAND0039"
                              name="BRAND0039"
                              aria-describedby="BRAND0039"
                              value={formik.values.BRAND0039}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.BRAND0039 &&
                            formik.errors.BRAND0039 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0039}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-2 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12 success-story-image-upload">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="BRAND0100"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setCaseStudy2(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "BRAND0100",
                                  toast,
                                  dispatch,
                                  headers,
                                  "branding"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          <ImagePreview
                            formikError={formik.errors.BRAND0100}
                            defaultImage={assets.BRAND0100}
                            newImage={caseStudy2}
                          />
                          {caseStudy2 &&
                            formik.touched.BRAND0100 &&
                            formik.errors.BRAND0100 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0100}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Panel-3 */}
                  <div className="col">
                    <div className="panel-card">
                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="BRAND0040"
                              name="BRAND0040"
                              aria-describedby="BRAND0040"
                              value={formik.values.BRAND0040}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.BRAND0040 &&
                            formik.errors.BRAND0040 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0040}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="grid grid-nogutter align-items-center mb-3">
                        <div className="col-12">
                          <div className="file-upload-section">
                            <PR.InputText
                              id="BRAND0041"
                              name="BRAND0041"
                              aria-describedby="BRAND0041"
                              value={formik.values.BRAND0041}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.BRAND0041 &&
                            formik.errors.BRAND0041 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0041}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Panel-3 Image */}
                      <div className="grid grid-nogutter align-items-center">
                        <div className="col-12 success-story-image-upload">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="BRAND0101"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setCaseStudy3(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "BRAND0101",
                                  toast,
                                  dispatch,
                                  headers,
                                  "branding"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          <ImagePreview
                            formikError={formik.errors.BRAND0101}
                            defaultImage={assets.BRAND0101}
                            newImage={caseStudy3}
                          />
                          {caseStudy3 &&
                            formik.touched.BRAND0101 &&
                            formik.errors.BRAND0101 ? (
                            <div className="error-msg">
                              {formik.errors.BRAND0101}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="grid">
                  <div className="col-4">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-4</h4>
                    </div>
                    <div className="label-input-wrapper align-items-center flex-column">
                      {/* Panel-4 Section */}
                      <div className="col-12">
                        <div className="panel-card">
                          {/* Panel-4 Header 1*/}
                          <div className="grid grid-nogutter align-items-center mb-3">
                            <div className="col-12">
                              <div className="file-upload-section">
                                <PR.InputText
                                  id="BRAND0042"
                                  name="BRAND0042"
                                  aria-describedby="BRAND0042"
                                  value={formik.values.BRAND0042}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {formik.touched.BRAND0042 &&
                                formik.errors.BRAND0042 ? (
                                <div className="error-msg">
                                  {formik.errors.BRAND0042}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {/* Panel-4 Header 2*/}
                          <div className="grid grid-nogutter align-items-center mb-3">
                            <div className="col-12">
                              <div className="file-upload-section">
                                <PR.InputText
                                  id="BRAND0092"
                                  name="BRAND0092"
                                  aria-describedby="BRAND0092"
                                  value={formik.values.BRAND0092}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {formik.touched.BRAND0092 &&
                                formik.errors.BRAND0092 ? (
                                <div className="error-msg">
                                  {formik.errors.BRAND0092}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {/* Panel-1 Image */}
                          <div className="grid grid-nogutter align-items-center">
                            <div className="col-12 success-story-image-upload">
                              <div className="file-upload-section">
                                <PR.InputText
                                  type="file"
                                  name="BRAND0102"
                                  className="file-upload-input"
                                  accept="image/png, image/jpeg"
                                  onChange={async (event) => {
                                    const file = event.currentTarget.files[0];
                                    setCaseStudy4(file);
                                    formik.handleChange(event);
                                    uploadWidthHeightImage(
                                      file,
                                      "BRAND0102",
                                      toast,
                                      dispatch,
                                      headers,
                                      "branding"
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              <ImagePreview
                                formikError={formik.errors.BRAND0102}
                                defaultImage={assets.BRAND0102}
                                newImage={caseStudy4}
                              />
                              {caseStudy4 &&
                                formik.touched.BRAND0102 &&
                                formik.errors.BRAND0102 ? (
                                <div className="error-msg">
                                  {formik.errors.BRAND0102}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="col-12 text-center">
                      <h4 className="mb-2 mt-0">Panel-5</h4>
                    </div>
                    <div className="label-input-wrapper align-items-center flex-column">
                      {/* Panel-5 Section */}
                      <div className="col-12">
                        <div className="panel-card">
                          {/* Panel-5 Header 1*/}
                          <div className="grid grid-nogutter align-items-center mb-3">
                            <div className="col-12">
                              <div className="file-upload-section">
                                <PR.InputText
                                  id="BRAND0043"
                                  name="BRAND0043"
                                  aria-describedby="BRAND0043"
                                  value={formik.values.BRAND0043}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {formik.touched.BRAND0043 &&
                                formik.errors.BRAND0043 ? (
                                <div className="error-msg">
                                  {formik.errors.BRAND0043}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {/* Panel-5 Header 2*/}
                          <div className="grid grid-nogutter align-items-center mb-3">
                            <div className="col-12">
                              <div className="file-upload-section">
                                <PR.InputText
                                  id="BRAND0044"
                                  name="BRAND0044"
                                  aria-describedby="BRAND0044"
                                  value={formik.values.BRAND0044}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              {formik.touched.BRAND0044 &&
                                formik.errors.BRAND0044 ? (
                                <div className="error-msg">
                                  {formik.errors.BRAND0044}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {/* Panel-2 Image */}
                          <div className="grid grid-nogutter align-items-center">
                            <div className="col-12 success-story-image-upload">
                              <div className="file-upload-section">
                                <PR.InputText
                                  type="file"
                                  name="BRAND0103"
                                  className="file-upload-input"
                                  accept="image/png, image/jpeg"
                                  onChange={async (event) => {
                                    const file = event.currentTarget.files[0];
                                    setCaseStudy5(file);
                                    formik.handleChange(event);
                                    uploadWidthHeightImage(
                                      file,
                                      "BRAND0103",
                                      toast,
                                      dispatch,
                                      headers,
                                      "branding"
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                              <ImagePreview
                                formikError={formik.errors.BRAND0103}
                                defaultImage={assets.BRAND0103}
                                newImage={caseStudy5}
                              />
                              {caseStudy5 &&
                                formik.touched.BRAND0103 &&
                                formik.errors.BRAND0103 ? (
                                <div className="error-msg">
                                  {formik.errors.BRAND0103}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    {/* Button Name */}
                    <div className="grid grid-nogutter align-items-center mb-1 mt-6">
                      <div className="col-12 mb-2">
                        <label htmlFor="BRAND0045" className="cmsLabels">
                          Button Name
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0045"
                            name="BRAND0045"
                            aria-describedby="BRAND0045"
                            value={formik.values.BRAND0045}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0045 && formik.errors.BRAND0045 ? (
                          <div className="error-msg">{formik.errors.BRAND0045}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />

          {/* Success Story Section */}
          <div className="grid-nogutter">
            <label htmlFor="unlockSection">
              <h3 className="mb-3 mt-1">Success Story Section</h3>
            </label>
            <div className="grid col-12">
              <div className="col-6">
                {/*  Title 1 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-2">
                    <label htmlFor="BRAND0046" className="cmsLabels">
                      Title 1
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0046"
                        name="BRAND0046"
                        aria-describedby="BRAND0046"
                        value={formik.values.BRAND0046}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0046 && formik.errors.BRAND0046 ? (
                      <div className="error-msg">{formik.errors.BRAND0046}</div>
                    ) : null}
                  </div>
                </div>

                {/*  Title 2 */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-2">
                    <label htmlFor="BRAND0047" className="cmsLabels">
                      Title 2
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0047"
                        name="BRAND0047"
                        aria-describedby="BRAND0047"
                        value={formik.values.BRAND0047}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0047 && formik.errors.BRAND0047 ? (
                      <div className="error-msg">{formik.errors.BRAND0047}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-6">
                {/*  Button Name */}
                <div className="grid grid-nogutter align-items-center mb-4">
                  <div className="col-2">
                    <label htmlFor="BRAND0001" className="cmsLabels">
                      Button Name
                    </label>
                  </div>
                  <div className="col-8">
                    <div className="card file-upload-section">
                      <PR.InputText
                        id="BRAND0001"
                        name="BRAND0001"
                        aria-describedby="BRAND0001"
                        value={formik.values.BRAND0001}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.BRAND0001 && formik.errors.BRAND0001 ? (
                      <div className="error-msg">{formik.errors.BRAND0001}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <label htmlFor="unlockSection">
                <h3 className="mb-3 mt-1">Images</h3>
              </label>
              <div className="grid">
                <div className="col">
                  <div className="col-12 text-center relative">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0104"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory1(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0104",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory1 &&
                        formik.touched.BRAND0104 &&
                        formik.errors.BRAND0104 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0104}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0104}
                        defaultImage={assets.BRAND0104}
                        newImage={successStory1}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0105"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory2(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0105",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory2 &&
                        formik.touched.BRAND0105 &&
                        formik.errors.BRAND0105 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0105}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0105}
                        defaultImage={assets.BRAND0105}
                        newImage={successStory2}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0106"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory3(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0106",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory3 &&
                        formik.touched.BRAND0106 &&
                        formik.errors.BRAND0106 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0106}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0106}
                        defaultImage={assets.BRAND0106}
                        newImage={successStory3}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0107"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory4(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0107",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory4 &&
                        formik.touched.BRAND0107 &&
                        formik.errors.BRAND0107 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0107}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0107}
                        defaultImage={assets.BRAND0107}
                        newImage={successStory4}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0108"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory5(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0108",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory5 &&
                        formik.touched.BRAND0108 &&
                        formik.errors.BRAND0108 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0108}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0108}
                        defaultImage={assets.BRAND0108}
                        newImage={successStory5}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid">
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0109"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory6(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0109",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory6 &&
                        formik.touched.BRAND0109 &&
                        formik.errors.BRAND0109 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0109}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0109}
                        defaultImage={assets.BRAND0109}
                        newImage={successStory6}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0110"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory7(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0110",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory7 &&
                        formik.touched.BRAND0110 &&
                        formik.errors.BRAND0110 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0110}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0110}
                        defaultImage={assets.BRAND0110}
                        newImage={successStory7}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0111"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory8(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0111",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory8 &&
                        formik.touched.BRAND0111 &&
                        formik.errors.BRAND0111 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0111}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0111}
                        defaultImage={assets.BRAND0111}
                        newImage={successStory8}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0112"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory9(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0112",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory9 &&
                        formik.touched.BRAND0112 &&
                        formik.errors.BRAND0112 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0112}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0112}
                        defaultImage={assets.BRAND0112}
                        newImage={successStory9}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0113"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory10(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0113",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory10 &&
                        formik.touched.BRAND0113 &&
                        formik.errors.BRAND0113 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0113}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0113}
                        defaultImage={assets.BRAND0113}
                        newImage={successStory10}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0114"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory11(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0114",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory11 &&
                        formik.touched.BRAND0114 &&
                        formik.errors.BRAND0114 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0114}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0114}
                        defaultImage={assets.BRAND0114}
                        newImage={successStory11}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0115"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory12(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0115",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory12 &&
                        formik.touched.BRAND0115 &&
                        formik.errors.BRAND0115 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0115}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0115}
                        defaultImage={assets.BRAND0115}
                        newImage={successStory12}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0116"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory13(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0116",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory13 &&
                        formik.touched.BRAND0116 &&
                        formik.errors.BRAND0116 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0116}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0116}
                        defaultImage={assets.BRAND0116}
                        newImage={successStory13}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0117"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory14(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0117",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory14 &&
                        formik.touched.BRAND0117 &&
                        formik.errors.BRAND0117 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0117}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0117}
                        defaultImage={assets.BRAND0117}
                        newImage={successStory14}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0118"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory15(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0118",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory15 &&
                        formik.touched.BRAND0118 &&
                        formik.errors.BRAND0118 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0118}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0118}
                        defaultImage={assets.BRAND0118}
                        newImage={successStory15}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0119"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory16(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0119",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory16 &&
                        formik.touched.BRAND0119 &&
                        formik.errors.BRAND0119 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0119}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0119}
                        defaultImage={assets.BRAND0119}
                        newImage={successStory16}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0120"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory17(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0120",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory17 &&
                        formik.touched.BRAND0120 &&
                        formik.errors.BRAND0120 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0120}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0120}
                        defaultImage={assets.BRAND0120}
                        newImage={successStory17}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0121"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory18(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0121",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory18 &&
                        formik.touched.BRAND0121 &&
                        formik.errors.BRAND0121 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0121}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0121}
                        defaultImage={assets.BRAND0121}
                        newImage={successStory18}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0122"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory19(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0122",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory19 &&
                        formik.touched.BRAND0122 &&
                        formik.errors.BRAND0122 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0122}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0122}
                        defaultImage={assets.BRAND0122}
                        newImage={successStory19}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="col-12 text-center">
                    <div className="success-story-image-upload">
                      <div className="file-upload-section">
                        <PR.InputText
                          type="file"
                          name="BRAND0123"
                          className="file-upload-input"
                          accept="image/png, image/jpeg"
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            setSuccessStory20(file);
                            formik.handleChange(event);
                            uploadWidthHeightImage(
                              file,
                              "BRAND0123",
                              toast,
                              dispatch,
                              headers,
                              "branding"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {successStory20 &&
                        formik.touched.BRAND0123 &&
                        formik.errors.BRAND0123 ? (
                        <div className="error-msg">
                          {formik.errors.BRAND0123}
                        </div>
                      ) : null}
                      <ImagePreview
                        formikError={formik.errors.BRAND0123}
                        defaultImage={assets.BRAND0123}
                        newImage={successStory20}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PR.Divider />
          {/* Partner Section */}
          <div className="file-upload">
            <div>
              <label htmlFor="partnerSection">
                <h3 className="mb-4 mt-1">Partner Section</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-6">
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-12 mb-2">
                        <label htmlFor="HOME0037">
                          <b>
                            Left Section Image{" "}
                            <span className="imageFormat">
                              (png, jpeg, 689W X 817H)
                            </span>
                          </b>
                        </label>
                      </div>
                      <div className="col-11 success-story-image-upload">
                          <div className="file-upload-section">
                            <PR.InputText
                              type="file"
                              name="BRAND0124"
                              className="file-upload-input"
                              accept="image/png, image/jpeg"
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                setPartnerLeftImage(file);
                                formik.handleChange(event);
                                uploadWidthHeightImage(
                                  file,
                                  "BRAND0124",
                                  toast,
                                  dispatch,
                                  headers,
                                  "branding"
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {partnerLeftImage &&
                              formik.touched.BRAND0124 &&
                              formik.errors.BRAND0124 ? (
                              <div className="error-msg">
                                {formik.errors.BRAND0124}
                              </div>
                            ) : null}
                          </div>
                          <ImagePreview
                              formikError={formik.errors.BRAND0124}
                              defaultImage={assets.BRAND0124}
                              newImage={partnerLeftImage}
                            />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="grid">
                      <div className="col-6">
                        <div className="grid grid-nogutter align-items-center mb-4">
                          <div className="col-12 mb-2">
                            <label htmlFor="BRAND0048">
                              <b>Title</b>
                            </label>
                          </div>
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="BRAND0048"
                                name="BRAND0048"
                                aria-describedby="BRAND0048"
                                value={formik.values.BRAND0048}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.BRAND0048 && formik.errors.BRAND0048 ? (
                              <div className="error-msg">{formik.errors.BRAND0048}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="grid grid-nogutter align-items-center mb-4">
                          <div className="col-12 mb-2">
                            <label htmlFor="BRAND0049">
                              <b>Sub Title</b>
                            </label>
                          </div>
                          <div className="col-12">
                            <div className="file-upload-section">
                              <PR.InputText
                                id="BRAND0049"
                                name="BRAND0049"
                                aria-describedby="BRAND0049"
                                value={formik.values.BRAND0049}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.BRAND0049 && formik.errors.BRAND0049 ? (
                              <div className="error-msg">{formik.errors.BRAND0049}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">

            </div>
          </div>

          <PR.Divider />

          <div className="file-upload">
            <div>
              <label htmlFor="partnerSection">
                <h3 className="mb-4 mt-1">Footer</h3>
              </label>
              <div className="card">
                <div className="grid">
                  <div className="col-6">
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="BRAND0002">
                          <b>Header-1.1</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0002"
                            name="BRAND0002"
                            aria-describedby="BRAND0002"
                            value={formik.values.BRAND0002}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0002 && formik.errors.BRAND0002 ? (
                          <div className="error-msg">
                            {formik.errors.BRAND0002}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-3">
                        <label htmlFor="BRAND0003">
                          <b>Header-1.2</b>
                        </label>
                      </div>
                      <div className="col-9">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0003"
                            name="BRAND0003"
                            aria-describedby="BRAND0003"
                            value={formik.values.BRAND0003}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0003 && formik.errors.BRAND0003 ? (
                          <div className="error-msg">
                            {formik.errors.BRAND0003}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    {/* Button 1*/}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-12 mb-2">
                        <label htmlFor="BRAND0004">
                          <b>Button Name-1.1</b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0004"
                            name="BRAND0004"
                            aria-describedby="BRAND0004"
                            value={formik.values.BRAND0004}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0004 && formik.errors.BRAND0004 ? (
                          <div className="error-msg">
                            {formik.errors.BRAND0004}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Button 1 */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-12 mb-2">
                        <label htmlFor="BRAND0005">
                          <b>Button Name-1.2</b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0005"
                            name="BRAND0005"
                            aria-describedby="BRAND0005"
                            value={formik.values.BRAND0005}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0005 && formik.errors.BRAND0005 ? (
                          <div className="error-msg">
                            {formik.errors.BRAND0005}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    {/* Button 2 */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-12 mb-2">
                        <label htmlFor="BRAND0006">
                          <b>Button Name-2</b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0006"
                            name="BRAND0006"
                            aria-describedby="BRAND0006"
                            value={formik.values.BRAND0006}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0006 && formik.errors.BRAND0006 ? (
                          <div className="error-msg">
                            {formik.errors.BRAND0006}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    {/* Button 3 */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-12 mb-2">
                        <label htmlFor="BRAND0007">
                          <b>Button Name-3.1</b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0007"
                            name="BRAND0007"
                            aria-describedby="BRAND0007"
                            value={formik.values.BRAND0007}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0007 && formik.errors.BRAND0007 ? (
                          <div className="error-msg">
                            {formik.errors.BRAND0007}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-12 mb-2">
                        <label htmlFor="BRAND0008">
                          <b>Button Name-3.2</b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0008"
                            name="BRAND0008"
                            aria-describedby="BRAND0008"
                            value={formik.values.BRAND0008}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0008 && formik.errors.BRAND0008 ? (
                          <div className="error-msg">
                            {formik.errors.BRAND0008}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    {/* Button 4 */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                      <div className="col-12 mb-2">
                        <label htmlFor="BRAND0009">
                          <b>Button Name-4</b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="file-upload-section">
                          <PR.InputText
                            id="BRAND0009"
                            name="BRAND0009"
                            aria-describedby="BRAND0009"
                            value={formik.values.BRAND0009}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.BRAND0009 && formik.errors.BRAND0009 ? (
                          <div className="error-msg">
                            {formik.errors.BRAND0009}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <PR.Divider />
          <div className="col-9">
            <PR.Button
              label="Save"
              type="submit"
              className="saveBtn mt-2"
              disabled={disableSave}
            />
          </div>
        </form>
      </div>
      <PR.Toast ref={toast} position="top-right" />
    </div>
  );
};
export default Shoppartner;